

  // ABOUT PAGE
  const sunBio = "Hi! My name is Sun. I'm a freelancer in Chicago. I teach and play piano at North Park University. I'm involved with Ensemble Affect and Ensemble Vitelotte, as well as various other projects. Check out the projects tab for examples. I'm a jazz kitten on keyboards and electric bass, and I also play viola da gamba. Thanks for visiting!";
  const officialBio = <><div className="bioParagraph">Sun Chang is a pianist and harpsichordist known for her adept interpretation of historical and contemporary works with a passion for building meaningful connections with her audiences through music. Sun studied with Dr. Peter Mack and later with Alan Chow at Northwestern University. She holds a Master of Arts degree in Harpsichord from the Royal Academy of Music, under tutelage of Carole Cerasi.</div>
                      <div className="bioParagraph">Sun's musical career is eclectic. Some ensembles and people she has worked with include: Haymarket Opera, ProMusica Chamber Orchestra, Friction Quartet, Vadim Gluzman, Trevor Pinnock, Ars Musica Chicago, WFMT Bach Organ Project, and American Bach Soloists. She has a keen interest in discovering and sharing music by historical women, and has co-founded Ensemble Vitelotte dedicated to this purpose.</div>
                      <div className="bioParagraph">Notably, Sun is also working on a solo album of keyboard suites by Élisabeth Jacquet de La Guerre recorded on piano, a project made possible with support by the Luminarts Foundation. She teaches at North Park University and plays with Ensemble Affect regularly. Sun is also a freelancer in Chicago.</div>
                      </>

export default () => <>
      <img src='media/sunsFace.jpg' id="sunsFace"/>
      <div className="bioParagraph">{ sunBio }</div>
      <h3>"Official" Bio</h3>
      { officialBio }
    </>;
