const collabContent = <><div className="left-align"><p>I am regularly playing with students at North Park University, and DePaul university. However, I have extensive experience playing with musicians of all levels! Contact me to schedule me for your recitals, juries, rehearsals, recordings, etc. I usually get back to my emails in 24-48 hours. If I do not respond, it’s possible that you ended up in my junk mailbox. I will try to check that occasionally.
</p>
<p>
Last minute requests are possible, since my sight reading is extremely proficient, but it may incur a late fee.
</p>
<h3 classname="left-align">Rates:</h3>

<div className="left-align">My rehearsal hourly rate is $40-70 (depending on repertoire difficulty).</div>
<div className="left-align">A performance is usually $100-150.</div>
<div className="left-align">If you have a project (for example, a junior/senior recital that requires many rehearsals, or a recording, we can think of a flat project rate).</div>
</div></>;
export default () => collabContent;
