  // EVENTS AND PROJECTS:
  const events = [
    {
     dateText: "November 17, 2024", 
     collaborators: "Loyola University",
     description: "I'll be joining Ignatian Voices for a concert at Madonna Della Strada (Loyola University). We will be sharing a program titled 'Ad Majoren Dei Gloriam: 1000 Years of Catholic Music.'", 
},
    {
      dateText: "September 20, 2024",
      collaborators: "Ensemble Affect", 
      description: "We invite you to join us for a meditative concert at the Yoga Co-Op. Visit ensembleaffect.org/concerts for more details!", 
    },
    
    {
      dateText: "October 20, 2024", 
      collaborators: "Alexandra Calabro", 
      description: "I'll be joining Alexandra Calabro for her lecture-recital given at the Early Music America Conference in Cleveland.", 
    },
{
      finalDateText: "October 26, 2024",
      dateText: "October 25-27, 2024", 
      collaborators: "The Oriana Voices", 
      description: "The Oriana Voices will present Monteverdi's Other Vespers.", 
    },
{

      dateText: "November 20, 2024", 
      collaborators: "Ensemble Vitelotte", 
      description: "Kiyoe and I will be presenting works by Emilie Mayer and Louise Farrenc at our duo recital at Pianoforte Chicago", 
    },
    { 
      dateText: "December 14, 2024",
      collaborators: "Lincoln Park Presbyterian Church", 
      description: "A Messiah Sing-Along!", 
    }, 
    {
      dateText: "December 13, 2024",
      collaborators: "Eden United Church of Christ",
      description: "Third Coast Chorus sings festive tunes, I accompany on the piano.", 
    }, 
{
      dateText: "December 21, 2024", 
      collaborators: "Trinity United Methodist Church 'the Home Alone Church'", 
      description: "Presenting works by Praetorius, Eccles, Bach, with the Callipygian Players for an annual Christmas music extravaganza.", 
    },

    {
      finalDateText: "January 17, 2025",
      dateText: "January 17-18, 2025", 
      collaborators: "First Presbyterian Church - Springfield", 
      description: "I will join the Illinois Symphony Orchestra for music involving harpsichord :)", 
    },

    {
      dateText: "May 10, 2025",
      collaborators: "Brava Theatre - San Francisco",
      description: "I'm joining Friction Quartet <3 and other incredible musicians to premiere a piece written by Paul Mortilla.", 
    },

    {
      finalDateText: "March 28, 2025",
      dateText: "March 28-30, 2025", 
      collaborators: "Bay Area, CA", 
      description: "We are playing in a few locations in the Bay Area as part of SF Early Music Society's programming." 
    },
    { 
      finalDateText: "January 24, 2025",
      dateText: "January 24-25, 2025",
      collaborators: "Landowska Harpsichord Society, The Yoga Co-op, River Park, Northbrook Public Library",
      description: "Ensemble Affect is descending onto Chicago for a jam-packed weekend featuring 4! concerts. The first is a house concert at the Landowska Harpsichord Society. Then, a strings-only (me on gamba) meditative concert at The Yoga Co-op. A concert with dancers at River Park. A Children's show at the library!"
}, 

{   
      dateText: "December 8, 2024",
      collaborators: "The Village Church of Northbrook",
      description: "Come sing along to Messiah while I shred some continuo."
}, 
    { 
      dateText: "December 13, 2024",
      finalDateText: "December 13 & 14, 2024",
      collaborators: "with Third Coast Choir",
      description: "Playing some seasonal accompaniment featuring Swedish sometimes!"
    }, 
    { 
      dateText: "April 13, 2025", 
      collaborators: "Elgin Community College", 
      description: "St. John Passion by JS Bach."
    },
    {
      dateText: "June 7, 2025",
      collaborators: "Northshore Chamber Music Festival",
      description: "Vadim Gluzman will shred heavily on the Four Seasons by Vivaldi. I will play harpsichord."
    }, 
    
    
    {
      dateText: "September 11, 2023",
      collaborators: "Brian Kelley",
      description: "Tuba DMA recital with Brian Kelley @ WVU. Works by John Williams, Broughton, Szentpali, Ralph Vaughn Wiliams",
    },
    
    {
      dateText: "October 22, 2023",
      collaborators: "Sarah Richards, Amanda Rodriguez, Levon Ritter",
      description: "A Soiree in Fox Chapel featuring vocal duets, Levon Ritter on plucked instruments and me on continuo duty, on gamba (!)",
    },

    {
      dateText: "November 4, 2023", 
      collaborators: "Ensemble Vitelotte",
      description: " Landowska Harpsichord Society presents Ensemble Vitelotte! Kiyoe on Baroque and Modern violins, me on harpsichord and piano. first half is baroque: works by Jacquet de La Guerre, Isabella Leonarda. Second half is classical: Sonata in D minor by Emilie Mayer (never recorded!), Nadia Boulanger & Germaine Tailleferre.",
    },
    {
      finalDateText: "January 28, 2023",
      dateText: "January 26-28, 2023",
      collaborators: "Ensemble Affect",
      description: "Some contemplative and chill music at the Chicago Yoga Co-op, featuring Kiyoe Matsuura, Phoebe Gelzer-Govatos, Levon Ritter. I'm playing gamba (again!)",
    },
    {
      finalDateText: "March 5, 2024",
      dateText: "March 2-5, 2024",
      collaborators: "Ensemble Affect",
      description: "Residency at Whitman College in Walla Walla, Washington. Presenting our 'Dance in Venice' program on March 5th",
    },
    
    {
      dateText: "May 1, 2024",
      collaborators: "Luminarts Foundation",
      description: "Recording / Recital of suites by Jacquet de La Guerre at Pianoforte in Chicago. The first step in my big project to record all of Jacquet de La Guerre's suites for harpsichord on piano, this concert will feature three suites, performed on a modern piano tuned to a historical temperament.",
    },
    
    {
        dateText: "March 9, 2024",
        collaborators: "Ensemble Affect",
        description: "Ensemble Affect will present the 'Dance in Venice' Program in the Bay Area.",
      },

      {
        dateText: "August 2024",
        collaborators: "Judith Collective",
        description: "Judith Collective is reuniting in London for a micro-festival of music by underrepresented composers. A very high chance that we will perform some Jacquet de La Guerre, and Emilie Mayer.",
      },

    {
        dateText: "April 28, 2024",
        collaborators: "Ensemble Vitelotte",
        description: "Ensemble Vitelotte (me + Kiyoe Matsuura) will be presenting two sonatas by Emilie Mayer, which have never been recorded and seldomly performed.",
    },

    {
      dateText: "April 5, 2023",
      collaborators: "Alexis Mason",
      description: "Recital with Saxophonist Alexis Mason at WVU.",
    },
    {
      dateText: "April 15, 2023",
      collaborators: "Ensemble Affect",
      description: "Some fun music with friends in the Bay Area.",
      forMoreInformation: {
        url: "ensembleAffectFlyer.pdf",
        text: "Flyer"
      }
    },
    {
      dateText: "April 20, 2023",
      collaborators: "Haymarket Opera",
      description: "Fundraiser concert with some folks in Chicago.",
      forMoreInformation: {
        url: "http://www.haymarketopera.org/2023cabaret",
        text: "Haymarket Website"
      }
    },
    {
      dateText: "April 29, 2023",
      collaborators: "Pittsburgh Baroque Orchestra",
      description: "Fifth Annual 18th Century Candlelight Concert @ Depreciation Lands Museum.",
    },
    { 
      dateText: "June 12, 2023",
      collaborators: "Kiyoe Matsuura",
      description: "A recital of chamber music by women @ Marilyn Horne Museum.",
    },
    {
      dateText: "May 4, 2024",
      collaborators: "Illinois Valley Symphony Orchestra",
      description: "Gershwin Concerto in F with maestro Daniel Sommerville! ",
      forMoreInformation: {
        url: "http://www.ivso.org/may-2024-concert",
        text: "ivso website"
      }
    },
    {
      dateText: "February 28, 2024",
      collaborators: "Jeff Siegfried",
      description: "Saxophonist / my friend Jeff and I will play a concert at Longwood at Oakmont, featuring Sonata in C# by Decruck and Tableaux de Provence by Paule Maurice.",
    }
  ]
  
  export default events;
